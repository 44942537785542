import React, { useEffect, useState,  } from 'react';
import { useNavigate } from 'react-router-dom';
import {isLoggedIn,isOutletSet} from '../../../utils/gaia';

import {Images} from '../../../utils/Images'

const Landing = (props) => {
  let navigate = useNavigate();

  useEffect(()=>{
    if(isLoggedIn()){

      navigate(`/app`)
    }
  },[])

  return(
    <div id="landing">
        <div className="inner">
          <div className="top">
           {/* <img className="landing-logo" src={Images.logo}/>*/}
          </div>
          <div className='btm'>
            <div>
              <button onClick={()=> {navigate("/login")}} className="btn bg-btn">LOGIN</button>
              <button onClick={()=> {navigate("/register")}} className="btn bg-btn">REGISTER</button>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Landing;
