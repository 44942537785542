import React, { useEffect,useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import {APIHOST, getToken,getMerchantDetail,CDN,RewardCDN} from '../../utils/gaia';
import Axios from 'axios';

import { Button,ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter,Collapse } from 'reactstrap';
import { Images } from '../../utils/Images';


const PointsModal = (props) => {

    const [loading ,setLoading] = useState(false);
    const [loaded ,setLoaded] = useState(false);
    const [points ,setPoints] = useState(props.points);
    const [point_sys ,setPointSys] = useState(props.point_sys);
    const [pnts ,setPnts] = useState(props.pnts);
    const [error ,setError] = useState(false);

    useEffect(()=>{
      setPnts(props.pnts)
      setPoints(props.points)
      setPointSys(props.point_sys)
    },[props])

    const itemSelected = (id) =>{
      if(props.pnts.indexOf(id.toString()) > -1){
        return true;
      }else{
        return false;
      }
    }

    return (
      < >
        <Modal isOpen={props.open} toggle={() => props.controller()}>
          <ModalBody>
          <div>
            <button className="close-modal" onClick={() => props.controller()}><i className="fa fa-times"></i></button>
            <div className="clearfix"></div>
          </div>
          <div className="rp-feed">
          <div className="points-pill mb-3">
          <div >
            <small>TOTAL POINTS</small>
            <h5>{points} PTS</h5>
          </div>
          </div>
            <div className="rwd-modal" >
              {point_sys.length > 0 ? 
              <>
              <div className="rp-itm-blk points-cnt">
                <div className="container  p-3">
                  <div className="row">
                  {point_sys.map(pnt =>(
                    <div id={`pntid_${pnt.id}`} key={pnt.id} className={"col-6 pnt" } >
                      <div className="points-box">
                        <img src={pnt.pic ? RewardCDN+pnt.pic : Images.noImage} />
                        <h3 className="title">{pnt.title}</h3>
                        <div className='pb-inner'>
                          <div className='l'>
                            <p className="points">{pnt.points} PTS</p>
                          </div>
                          <div class="custom-radio">
                            <input type="checkbox" name="point" checked={itemSelected(pnt.id)} title={pnt.title} id={`pnt_${pnt.id}`} onChange={(evt)=>props.selectPoints(evt,pnt)} />
                            <label for={`pnt_${pnt.id}`}></label>
                          </div>
                        </div>
                      </div> 

                    </div>
                  ))}
                  </div>
                </div>
              </div>
              <div className='pop-footer'>
                <button onClick={props.claim} className="tmpl-main-btn filled">Redeem Points</button>
              </div>
              </>: <div className="no-itm">No Rewards Available</div>}
            </div>
          </div>  
          </ModalBody>
        </Modal>
      </>
    );
}

export default PointsModal;