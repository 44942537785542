import React, { useEffect,useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import {APIHOST, getToken,getMerchantDetail,CDN,RewardCDN} from '../../utils/gaia';
import Axios from 'axios';

import { Button,ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter,Collapse } from 'reactstrap';
import { Images } from '../../utils/Images';


const BundleModal = (props) => {

    const [loading ,setLoading] = useState(false);
    const [loaded ,setLoaded] = useState(false);
    const [bundle ,setBundle] = useState(props.bundle);
    const [bdls ,setBdls] = useState(props.bdls);
    const [error ,setError] = useState(false);

    useEffect(()=>{
      setBdls(props.bdls)
      setBundle(props.bundle)
    },[props])

    const itemSelected = (id) =>{
      if(props.bdls.indexOf(id.toString()) > -1){
        return true;
      }else{
        return false;
      }
    }

    return (
      < >
        <Modal isOpen={props.open} toggle={() => props.controller()}>
          <ModalBody>
          <div>
            <button className="close-modal" onClick={() => props.controller()}><i className="fa fa-times"></i></button>
            <div className="clearfix"></div>
          </div>
          <div className="rp-feed">
          <div className="points-pill mb-3">
          <div >
            <h5>{bundle.package_title}</h5>
          </div>
          </div>
            <div className="rwd-modal" >
              {bundle.items.length > 0 ? 
              <>
              <div className="rp-itm-blk points-cnt">
                <div className="container  p-3">
                  <div className="row">
                  {bundle.items.map(bdl =>(
                    <div id={`bdlid_${bdl.id}`} key={bdl.id} className={"col-6 bdl" } >
                      <div className="points-box">
                        <img src={bdl.pic ? RewardCDN+bdl.pic : Images.noImage} />
                        <h3 className="title">{bdl.title}</h3>
                        <div className='pb-inner'>
                          <div className='l'>
                            <p className="points"></p>
                          </div>
                          <div class="custom-radio">
                            <input type="checkbox" name="bundle_item" checked={itemSelected(bdl.id)} title={bdl.title} id={`bdl_${bdl.id}`} onChange={(evt)=>props.selectBundleItem(evt,bdl)} />
                            <label for={`bdl_${bdl.id}`}></label>
                          </div>
                        </div>
                      </div> 

                    </div>
                  ))}
                  </div>
                </div>
              </div>
              <div className='bundle-action'>
                <button onClick={props.redeem} className="filled">Redeem</button>
                <button onClick={props.gift} className="inverse">Gift</button>
              </div>
              </>: <div className="no-itm">No Rewards Available</div>}
            </div>
          </div>  
          </ModalBody>
        </Modal>
      </>
    );
}

export default BundleModal;