import React, { useEffect,useState } from 'react';
import './starRating.css'

const StarRating = (props) => {
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const onRate = (index) =>{
      setRating(index)
      props.onChange(props.options[index -1],props.id)
    }
    return (
      <div className="star-rating">
        <span className='tags'>{props.options[0]}</span>
        {[...Array(props.options.length)].map((star, index) => {
          index += 1;
          return (
            <button
              type="button"
              key={index}
              className={index <= (hover || rating) ? "on" : "off"}
              onClick={() => onRate(index)}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(rating)}
            >
              <span className="star">&#9733;</span>
            </button>
          );
        })}
        <span className='tags'>{props.options[props.options.length - 1]}</span>
      </div>
    );
  };

  export default StarRating;