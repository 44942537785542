import React, { Component, useEffect, useState } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {APIHOST,updateloginStatus,setMerchant,setToken,isLoggedIn,isOutletSet, loadSuccess, loadError, merchantKey,merchantId, RewardCDN} from '../../utils/gaia';
import ToolBar from '../Components/toolbar'
import classnames from 'classnames';
import Switch from "react-switch";
import Buttontb from '../Components/Button'
import commonPassword from 'common-password'


import { Form, FormGroup, Label, Input, ButtonGroup,ButtonDropdown,DropdownToggle, DropdownItem,DropdownMenu } from 'reactstrap';
import { Images } from '../../utils/Images';

const loadInit = {
  firstname : '',
  phone : '',
  gender : '',
  dob_d : 'DD',
  dob_m : 'MM',
  dob_y : 'YYYY',
  postcode : '',
  password : '',
  npassword : '',
  cpassword : '',
  grp : [],
  subs : []
}
 
const Profile = (props) => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState(loadInit)
  const [loading, setLoading] = useState(false)
  const [canEdit, setCanEdit] = useState(false)
  const [pageLoaded, setPageLoaded] = useState(false)
  const [pageError, setPageError] = useState(false)
  const [checked, setChecked] = useState(false)

  const [genderOpen, setGenderOpen] = useState(false)
  const [dayOpen, setDayOpen] = useState(false)
  const [yearOpen, setYearOpen] = useState(false)
  const [monthOpen, setMonthOpen] = useState(false)
  
  const [load, setLoad] = useState(loadInit)

  useEffect(()=>{
    if(!isLoggedIn()){
      navigate(`/login`)
    }else{
      loadProfile()
    }
  },[])

  

  const toggleChk = (chk) =>{
    setLoading(true)
    setPageLoaded(false);
    Axios.post(`${APIHOST}apian/wphook/autorenew`, {
      mct: merchantId,
      sid: profile.subs.id,
      status : chk ? 1 : 0
    }).then(function(res){
        let {data} = res;
        loadSuccess()
        loadProfile()
    }).catch(function(er){
      setLoading(false)
      setPageLoaded(true);
      setPageError(false)
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          loadError(er.response.data.error)
        }else{
          loadError("A network error occured, please try again later.")
        }
      }else{
        loadError(er.message)
      }
      
    })
  }

  const toggleEdit = (c) =>{
    setCanEdit(!canEdit);
  }

  const loadProfile = (evt) =>{
    setLoading(true)
    setPageError(false)
    setPageLoaded(false)
    Axios.post(`${APIHOST}apian/wphook/me`, {
      mct: merchantId,
    }).then(function(res){
        let {data} = res;
        setLoading(false)
        let payload = {
          firstname : data.profile.firstname,
          phone : data.profile.phone,
          email : data.profile.email,
          gender : data.profile.gender == "" ? "Select..." : data.profile.gender,
          postcode : data.profile.postcode,
          password : '',
          npassword : '',
          cpassword : '',
          dob_d : data.profile.dob[2],
          dob_m : data.profile.dob[1],
          dob_y : data.profile.dob[0],
          grp : data.profile.grp,
          subs : data.profile.subs
        }
        setChecked(data.profile.subs ? data.profile.subs.auto_renew : false)
        setProfile(payload)
        setPageLoaded(true);
        setPageError(false)
    }).catch(function(er){
      setLoading(false)
      setPageLoaded(true);
      setPageError(true)
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          loadError(er.response.data.error)
        }else{
          loadError("A network error occured, please try again later.")
        }
      }else{
        loadError(er.message)
      }
      
    })
  }

  const handleChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  const genYears = () =>{
    let cap = new Date().getFullYear() - 16;
    let payload = []
    for(let i = cap; i >= 1900; i--){
      payload.push(<DropdownItem onClick={()=>handleDD("y",i)}>{i}</DropdownItem>)
    }
    return payload
  }

  const genMonths = () =>{
    let payload = []
    for(let i = 1; i <= 12; i++){
      payload.push(<DropdownItem onClick={()=>handleDD("m",i)}>{i}</DropdownItem>)
    }
    return payload
  }

  const genDays = () =>{
    let payload = []
    for(let i = 1; i <= 31; i++){
      payload.push(<DropdownItem onClick={()=>handleDD("d",i)}>{i}</DropdownItem>)
    }
    return payload
  }



  const toggle = (type) =>{
    if(type == "gender"){
      setGenderOpen(!genderOpen);
    }else if(type == "d"){
      setDayOpen(!dayOpen);
    }else if(type == "m"){
      setMonthOpen(!monthOpen);
    }else if(type == "y"){
      setYearOpen(!yearOpen);
    }
    
  } 

  const handleSubmit = (evt) =>{
    evt.preventDefault();
    if(profile.firstname === ""){
      loadError("Please enter your name.")
      return;
    }

    if(profile.phone === "" || profile.phone.length < 8){
      loadError("Please enter a valid hand phone number")
      return;
    }

    if(profile.dob_d === "DD" || profile.dob_m == "MM" || profile.dob_y == "YYYY"){
      loadError("Please enter a valid date.")
      return;
    }

    if(profile.password != "" || profile.npassword != "" || profile.cpassword != ""){
      if(profile.npassword === "" ||  profile.npassword.length < 6){
        loadError("Please enter a valid password, (min: 6 characters).")
        return;
      }
  
      if(profile.npassword !== profile.cpassword){
        loadError("Passwords do not match")
        return;
      }

    }

    let dob = `${profile.dob_y}-${profile.dob_m}-${profile.dob_d}`
    
    setLoading(true);
    Axios.post(`${APIHOST}apian/wphook/editProfile`, {
      email : profile.email,
      fullname : profile.firstname,
      pwd : profile.npassword,
      old : profile.password,
      postcode : profile.postcode,
      phone : profile.phone,
      gender : profile.gender,
      dob : dob,
      mct : merchantKey,
    }).then(function(res){
        let {data} = res;
        loadSuccess("Profile updated successfully.")
    }).catch(function(er){
      setLoading(false);
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          loadError(er.response.data.error)
        }else{
          loadError("A network error occured, please try again later.")
        }
      }else{
        loadError(er.message)
      }
      
    })
  }

  const handleDD = (type,val) =>{
    let load = profile;
    if(type == "g"){
      load.gender = val;
    }else if(type == "d"){
      load.dob_d = val;
    }else if(type == "m"){
      load.dob_m = val;
    }else if(type == "y"){
      load.dob_y = val;
    } 
    setProfile(load);
  }

    return (
      <>
      <div className="container no-pad">
        <ToolBar link="profile" past="/" hasMenu={false} />
        {pageLoaded ?
        <>
        {!pageError ?
        <>
        <div id="profile">
        <div className="inner">
          <h4 className='fullname'>{profile.firstname}</h4>
          {!canEdit ?
          <>{profile.subs ?<p className='member'><i className='fa fa-star'></i> MEMBER</p> : ""}
          <button className='edit-btn' onClick={toggleEdit}>EDIT PROFILE</button>
          {profile.subs ?
          <div className='active-sub'>
            <p className='bar'>MEMBERSHIP VALIDITY PERIOD : {profile.subs.start_date} - {profile.subs.end_date}</p>
            <div className='auto-renew-jumbotron'>
              <div className='in'>
                <h5>MEMBERSHIP AUTO RENEW</h5>
                <p>(Next Auto-renewal on the {profile.subs.next_renew}.)</p>
                <Switch offColor='#f9a753' onColor='#440e6b' uncheckedIcon={<span className='switch-icon'>OFF</span>} checkedIcon={<span className='switch-icon'>ON</span>} width={70} offHandleColor='#440e6b' onHandleColor='#f9a753' onChange={toggleChk} checked={checked} />
              </div>
            </div>
          </div>: 
          <div className='subscription-list'> 
            {profile.grp.map(item =>(
            <div className='sub-item'>
              <img src={item.banner == "" ? Images.sample : `${RewardCDN}${item.banner}`} className="nav-banner" />
              <p>{item.grp_descr}</p>
              <a onClick={()=>navigate('/app/subscription',{state:item})}>SIGN UP FOR MEMBERSHIP</a>
              <p className='note'>You will need to pay a cost of ${item.cost} when applying*</p>
            </div>
          ))}
          </div>}</>:
          <div className="frm-box">
          <Form autocomplete="off" onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="username">Full Name</Label>
              <Input className="text-field" type="text" id="firstname" name="firstname"  placeholder='Enter your fullname' value={profile.firstname} onChange={event => handleChange(event)} required />
            </FormGroup>
            <FormGroup>
              <Label for="username">Phone Number</Label>
              <Input className="text-field" type="number" id="phone" name="phone" placeholder='Enter your mobile number' value={profile.phone} onChange={event => handleChange(event)} required />
            </FormGroup>
            <FormGroup>
              <Label for="postcode">Postal code of your residing address</Label>
              <Input className="text-field" type="text" id="postcode" autoComplete='off' name="postcode" placeholder='Enter your postal code' value={profile.postcode} onChange={event => handleChange(event)} required />
            </FormGroup>
            <div className='row mb-4'>
              <div className='col-5'>
                <Label for="username" className='show' >Gender</Label>
                <ButtonDropdown isOpen={genderOpen} toggle={()=>toggle("gender")}>
                  <DropdownToggle className='tb-form-dd' caret>
                    {profile.gender}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={()=>handleDD("g","Male")}>Male</DropdownItem>
                    <DropdownItem onClick={()=>handleDD("g","Female")}>Female</DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
              </div>
              <div className='col-7'>
              <Label for="username">Birthday</Label>
              <ButtonGroup>
                <ButtonDropdown isOpen={dayOpen} toggle={()=>toggle("d")}>
                  <DropdownToggle className='tb-form-dd' caret>
                    {profile.dob_d}
                  </DropdownToggle>
                  <DropdownMenu>
                  {genDays().map((item)=>(item))}
                  </DropdownMenu>
                </ButtonDropdown>
                <ButtonDropdown isOpen={monthOpen} toggle={()=>toggle("m")}>
                  <DropdownToggle className='tb-form-dd' caret>
                    {profile.dob_m}
                  </DropdownToggle>
                  <DropdownMenu>
                  {genMonths().map((item)=>(item))}
                  </DropdownMenu>
                </ButtonDropdown>
                <ButtonDropdown isOpen={yearOpen} toggle={()=>toggle("y")}>
                  <DropdownToggle className='tb-form-dd' caret>
                    {profile.dob_y}
                  </DropdownToggle>
                  <DropdownMenu>
                    {genYears().map((item)=>(item))}
                  </DropdownMenu>
                </ButtonDropdown>
              </ButtonGroup>
              </div>
            </div>
            <FormGroup  className="mb-4">
              <Label for="username">Current Password (verify it's you)</Label>
              <Input className="text-field" autocomplete="new-password" type="password" id="password" name="password" placeholder='Enter your current Password' onChange={event => handleChange(event)} />
            </FormGroup>
            <FormGroup  className="mb-4">
              <Label for="username">Password</Label>
              <Input className="text-field" autocomplete="new-password" type="password" id="npassword" name="npassword" placeholder='Enter your new Password' onChange={event => handleChange(event)} />
            </FormGroup>
            <FormGroup  className="mb-4">
              <Label for="username">Confirm Password</Label>
              <Input className="text-field" type="password" autocomplete="new-password" id="cpassword" name="cpassword" placeholder='Confirm your Password' onChange={event => handleChange(event)} />
            </FormGroup>
            <Buttontb loader={loading} text={"REGISTER"} classList={"tmpl-main-btn filled"} />
          </Form>
        </div>}
        </div>:

        </div>
        </>:
        <div className='error-screen'>
          <div>
            <p>An error occured, please reload page.</p>
            <a className='reload-btn' onClick={loadProfile}>RELOAD</a>
          </div>
        </div>}
        </>:
        <div className='loader'>
          <div>
            <i className='fa fa-spinner fa-spin fa-4x'></i>
            <p>Loading...</p>
          </div>
        </div>}
      </div>
    </>
    );
}

export default Profile;
