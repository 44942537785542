import React, { Component, useEffect, useRef, useState } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {APIHOST,updateloginStatus,setMerchant,setToken,isLoggedIn,isOutletSet, loadSuccess, loadError, merchantKey,merchantId, RewardCDN, showLoading} from '../../../utils/gaia';
import useWindowDimensions from '../../../utils/useWindowDimensions';
import ToolBar from '../../Components/toolbar'
import WheelComponent from '../../Components/SpinWheel'
import StarRating from '../../Components/StarRating'

import './survey.css'

import { Images } from '../../../utils/Images';
import { Form, FormGroup, Label, Input } from 'reactstrap';
 
const Survey = (props) => {
  const navigate = useNavigate();
  const [load, setLoad] = useState([])
  const [result, setResult] = useState([])
  const [survey, setSurvey] = useState([])
  const [loading, setLoading] = useState(false)
  const [pageLoaded, setPageLoaded] = useState(false)
  const [pageError, setPageError] = useState(false)
  useEffect(()=>{
    if(!isLoggedIn()){
      navigate(`/login`)
    }else{
      loadSurvey()
    }
  },[])

  const onFinished = (evt) => {
    evt.preventDefault();
    let canSubmit = true
    result.forEach(item => {
      if(item.r == 1 && item.ans == ""){
        canSubmit = false;
      }
    }); 
    if(!canSubmit){
      loadError("Please fill all required fields.")
      return
    }
    showLoading("Please wait...");
    Axios.post(`${APIHOST}apian/wphook/submitSurvey`, {
      sid : survey.id,
      result : JSON.stringify(result),
      mct : merchantId,
    }).then(function(res){
        let {data} = res;
        loadSuccess("Thanks for participating.")
    }).catch(function(er){
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          loadError(er.response.data.error)
        }else{
          loadError("A network error occured, please try again later.")
        }
      }else{
        loadError(er.message)
      }
      loadSurvey();
    })
  }

  const loadSurvey = (evt) =>{
    setLoading(true)
    setPageError(false)
    setPageLoaded(false)
    Axios.post(`${APIHOST}apian/wphook/survey`, {
      mct: merchantId,
    }).then(function(res){
        let {data} = res;
        setLoading(false)
        if(data.survey){
          data.survey.json_body = JSON.parse(data.survey.json_body);
          let res = [];
          data.survey.json_body.forEach((itm,i) => {
            res.push({id : `q_${i}`, que : itm.question, ans : "", r : itm.required == "yes" ? 1 : 0})
          });
          setResult(res);
        }
        setSurvey(data.survey)
        setPageLoaded(true);
        setPageError(false)
    }).catch(function(er){
      setLoading(false)
      setPageLoaded(true);
      setPageError(true)
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          loadError(er.response.data.error)
        }else{
          loadError("A network error occured, please try again later.")
        }
      }else{
        loadError(er.message)
      }
      
    })
  }

  const starChange = (rating,id) => {
    let key = id.split("_")[1];
    let res = result;
    res[key].ans = rating
    setResult(res);
  }


  const genFormElement = (itm,i) =>{
    /****
      0 : Text
      1 : TextArea
      2 : CheckBox
      3 : Radio
      4 : Select
    ****/
    if(itm.ansType == "0"){
      return <>
        <FormGroup className='txt' key={i}>
          <Label for={`q_${i}`}>{`${itm.question}`}{itm.required == "yes" ? <span className='req'>*</span> : ""}</Label>
          <Input className="text-field" type="text" id={`q_${i}`} name={`q_${i}`} placeholder='Enter answer here' onChange={event => setAns(event.target)} required={itm.required == "yes" ? "required" : ""} />
        </FormGroup>
      </>
    }else if(itm.ansType == "1"){
      return <>
        <FormGroup key={i} className='txt-area'>
          <Label for={`q_${i}`}>{itm.question}{itm.required == "yes" ? <span className='req'>*</span> : ""}</Label>
          <Input className="text-field" type="textarea" rows="6" max="120" placeholder="Enter Max of 120 Characters" id={`q_${i}`} name={`q_${i}`} onChange={event => setAns(event.target)} required={itm.required == "yes" ? "required" : ""} />
        </FormGroup>
      </>
    }else if(itm.ansType == "2"){
      let opt = itm.options.split(",");
      return <>
        <FormGroup className='check' check>
        <Label for={`q_${i}`}>{itm.question}{itm.required == "yes" ? <span className='req'>*</span> : ""}</Label>
        {opt.map(itm=>(
          <Label className='chklbl' check>
            <Input onChange={event => setChk(event.target)} name={`chk_${i}`} value={itm} type="checkbox" />{` ${itm}`}
          </Label>
        ))}
        </FormGroup>
      </>
    }else if(itm.ansType == "3"){
      let opt = itm.options.split(",");
      return <>
        <FormGroup key={i} className='q-radio'>
          <Label for={`q_${i}`}>{itm.question}{itm.required == "yes" ? <span className='req'>*</span> : ""}</Label>
          <StarRating id={`q_${i}`} onChange={(rating,id)=>{starChange(rating,id)}} options={opt} />
        </FormGroup>
      </>
    }else if(itm.ansType == "4"){
      let opt = itm.options.split(",");
      return <>
        <FormGroup key={i} className='select'>
          <Label for={`q_${i}`}>{itm.question}{itm.required == "yes" ? <span className='req'>*</span> : ""}</Label>
          <Input className="" type="select"  id={`q_${i}`} name={`q_${i}`} onChange={event => setAns(event.target)} required={itm.required == "yes" ? "required" : ""}>
            <option value="">Select one</option>
            {opt.map(itm=>(
              <option value={itm}>{itm}</option>
            ))}
          </Input>
        </FormGroup>
      </>
    }
    
  }

  const setAns = (evt) =>{
    let key = evt.id.split("_")[1];
    let res = result;
    res[key].ans = evt.value
    setResult(res);
  }

  const setChk = (evt) =>{
    let key = evt.name.split("_")[1];
    let res = result;
    let r = res[key].ans.split("|")
    r = r[0] == "" ? [] : r;
    let value = evt.value.replace(" ", "")
    let index = r.indexOf(value);
    if(index == -1){
      r.push(value)
    }else{
      r.splice(index,1)
    }
    res[key].ans = r.join("|")
    setResult(res);
  }



    return (
      <>
      <div className="container bg-gray no-pad">
        <ToolBar link="survey" past="/" hasMenu={false} />
        {pageLoaded ?
        <>
        {!pageError ?
        <>
        <div id="survey">
          <div className="inner">
            <h4 className='m-title'>SURVEY</h4>
            {!survey ? 
            <div className="empty-list">No survey available.</div>:
            <>
            {survey.caption && <p className='caption-text'>{survey.caption}</p>}
            <div className='questionnaire'>
              <Form onSubmit={onFinished}>
                {survey.json_body.map((item,i)=>(
                  <div key={i}>{genFormElement(item,i)}</div>
                ))}
                <button className='submit-button'>SUBMIT</button>
              </Form>
              <div className='terms'>{survey.terms}</div>
            </div>
            </>}
          </div>
        </div>
        </>:
        <div className='error-screen'>
          <div>
            <p>An error occured, please reload page.</p>
            <a className='reload-btn' onClick={loadSurvey}>RELOAD</a>
          </div>
        </div>}
        </>:
        <div className='loader'>
          <div>
            <i className='fa fa-spinner fa-spin fa-4x'></i>
            <p>Loading...</p>
          </div>
        </div>}
      </div>
    </>
    );
}

export default Survey;
