import React, { useEffect,useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import {APIHOST, getToken,getMerchantDetail,CDN,RewardCDN} from '../../utils/gaia';
import Axios from 'axios';

import { Button,ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter,Collapse } from 'reactstrap';
import { Images } from '../../utils/Images';


const PopupModal = (props) => {

    useEffect(()=>{
 
    },[props])


    return (
      < >
        <Modal className='modal-dialog-centered' isOpen={props.open} toggle={() => props.controller()}>
          <ModalBody>
          <button className="close-modal" onClick={() => props.controller()}><i className="fa fa-times"></i></button>
          <a href={props.link}><img src={props.popup} /></a>
          </ModalBody>
        </Modal>
      </>
    );
}

export default PopupModal;