import React, { Component, useEffect, useState } from 'react';
import Axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import {APIHOST,updateloginStatus,setMerchant,setToken,isLoggedIn,isOutletSet, loadSuccess, loadError, merchantKey} from '../../../utils/gaia';
import ToolBar from '../../Components/toolbarNL'
import Buttontb from '../../Components/Button'


import { Button, Form, FormGroup, Label, Input, FormText,Spinner } from 'reactstrap';
 
const NewPwd = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [confirm, setConfirm] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    if(isLoggedIn()){
      navigate(`/app`)
    }else{
      if(typeof location.state.key == "undefined"){
        navigate('/forgot')
      }
    }
  },[])

  const handleSubmit = (evt) =>{
    evt.preventDefault();
    if(confirm !==  password){
      loadError("Passwords do not match.")
      return;
    }
    setLoading(true)
    Axios.post(`${APIHOST}forgotv1`, {
      password : password,
      key: location.state.key,
      action : "reset"
    }).then(function(res){
        let {data} = res;
        loadSuccess(data.msg)
        setTimeout(()=>{  window.location.replace(`/login`)},1500)
    }).catch(function(er){
      setLoading(false)
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          loadError(er.response.data.error)
        }else{
          loadError("A network error occured, please try again later.")
        }
      }else{
        loadError(er.message)
      }
      
    })
  }

    return (
      <>
      <div className="container bg">
      <ToolBar title="login" past="/" hasMenu={false} />
      
        <div id="login">
        <button onClick={()=> navigate("/")} className="btn back-btn" ><i className="fa fa-chevron-left"></i></button>
        <div className="inner">
          <div className="top">
            <h1>Reset Your Password</h1>
            <div className="frm-box">
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <FormGroup  className="mb-4">
                  <Label for="username">New Password</Label>
                  <Input className="text-field" type="password" id="password" name="password" placeholder='Enter your password' onChange={event => setPassword(event.target.value)} required />
                </FormGroup>
                <FormGroup  className="mb-4">
                  <Label for="username">Confirm Password</Label>
                  <Input className="text-field" type="password" id="confirm" name="confirm" placeholder='Confirm your password' onChange={event => setConfirm(event.target.value)} required />
                </FormGroup>
              <Buttontb loader={loading} text={"RESET"} classList={"tmpl-main-btn filled"} />
              </Form>
              <p className='form-links'>
                <span><a href="/login">Already a member</a></span>|<span><a href="/register">Haven't registered yet?</a></span>
              </p>
            </div>
          </div>
          <div className="btm">
           
          </div>
        </div>
      </div>
    </div>
    </>
    );
}

export default NewPwd;
