import React, { Component, useEffect, useState } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {APIHOST,updateloginStatus,setMerchant,setToken,isLoggedIn,isOutletSet, loadSuccess, loadError, merchantKey,merchantId, RewardCDN} from '../../../utils/gaia';
import ToolBar from '../../Components/toolbar'
import classnames from 'classnames';
import Switch from "react-switch";
import Buttontb from '../../Components/Button'
import commonPassword from 'common-password'

import './contact.css'


import { Collapse } from 'reactstrap';
import { Images } from '../../../utils/Images';
 
const ContactUs = (props) => {
  const navigate = useNavigate();
  const [info, setInfo] = useState([])
  const [loading, setLoading] = useState(false)
  const [collapsibles, setCollapsibles] = useState([])
  const [pageLoaded, setPageLoaded] = useState(false)
  const [pageError, setPageError] = useState(false)
  useEffect(()=>{
    if(!isLoggedIn()){
      navigate(`/login`)
    }else{
      loadInfo()
    }
  },[])


  const loadInfo = (evt) =>{
    setLoading(true)
    setPageError(false)
    setPageLoaded(false)
    Axios.post(`${APIHOST}apian/wphook/contactInfo`, {
      mct: merchantId,
    }).then(function(res){
        let {data} = res;
        setLoading(false)
        setInfo(data.contact)

        data.contact.forEach(element => {//create collapsible id
          let c = collapsibles;
          c[element.id] = false;
          setCollapsibles(c)
        });

        setPageLoaded(true);
        setPageError(false)
    }).catch(function(er){
      setLoading(false)
      setPageLoaded(true);
      setPageError(true)
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          loadError(er.response.data.error)
        }else{
          loadError("A network error occured, please try again later.")
        }
      }else{
        loadError(er.message)
      }
      
    })
  }


  const toggleCollapse = (el) =>{
    setCollapsibles(Object.assign({}, collapsibles, {[el] : !collapsibles[el]}))
  }

  

  const handleSubmit = (evt) =>{
    evt.preventDefault();
    /*if(profile.firstname === ""){
      loadError("Please enter your name.")
      return;
    }*/
    setLoading(true);
    Axios.post(`${APIHOST}apian/wphook/editProfile`, {
      msg : "",
      mct : merchantKey,
    }).then(function(res){
        let {data} = res;
        loadSuccess("Profile updated successfully.")
    }).catch(function(er){
      setLoading(false);
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          loadError(er.response.data.error)
        }else{
          loadError("A network error occured, please try again later.")
        }
      }else{
        loadError(er.message)
      }
      
    })
  }


    return (
      <>
      <div className="container bg-gray no-pad">
        <ToolBar link="contact" past="/" hasMenu={false} />
        {pageLoaded ?
        <>
        {!pageError ?
        <>
        <div id="contact">
          <div className="inner">
            <h4 className='m-title'>CONTACT US</h4>
            {info.map(ifo =>(
              <div key={ifo.id} className="contact-container mt-2">
                <div className="contact"  onClick={()=>toggleCollapse(ifo.id)}>
                  <div className="contact-title">
                     <h4>- {ifo.location}</h4>
                  </div>
                  <div className="info-icon">
                    {collapsibles[ifo.id] ? <i class="fas fa-caret-up"></i> : <i class="fas fa-caret-down"></i>}
                  </div>
                </div>
                <div className='clearfix'></div>
                <Collapse className="ifo-itms" isOpen={collapsibles[ifo.id]}>
                  <p className='info-label'>Service Options</p>
                  <p className='info-text'>{ifo.service_options}</p>
                  <p className='info-label'>Located In</p>
                  <p className='info-text'>{ifo.location}</p>
                  <p className='info-label'>Address</p>
                  <p className='info-text'>{ifo.address}</p>
                  <p className='info-label'>Opening Hours</p>
                  <p className='info-text'>{(JSON.parse(ifo.opening_hours)).map((item,i)=>(<><span key={i}>{item.day} : {item.range}</span><br /></>))}</p>
                  <p className='info-label'>Menu</p>
                  <p className='info-text'>{ifo.menu}</p>
                  <p className='info-label'>Phone</p>
                  <p className='info-text'>{ifo.phone}</p>
                </Collapse>
              </div>
            ))}
          </div>
        </div>
        </>:
        <div className='error-screen'>
          <div>
            <p>An error occured, please reload page.</p>
            <a className='reload-btn' onClick={loadInfo}>RELOAD</a>
          </div>
        </div>}
        </>:
        <div className='loader'>
          <div>
            <i className='fa fa-spinner fa-spin fa-4x'></i>
            <p>Loading...</p>
          </div>
        </div>}
      </div>
    </>
    );
}

export default ContactUs;
