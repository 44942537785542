import React, { Component, useEffect, useRef, useState } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {APIHOST,isLoggedIn, loadError, merchantKey,merchantId, setBanner, getBanner} from '../../../utils/gaia';
import ToolBar from '../../Components/toolbar'

import './notification.css'

import { Images } from '../../../utils/Images';
import { Form, FormGroup, Label, Input } from 'reactstrap';
 
const Notification = (props) => {
  const navigate = useNavigate();
  const [load, setLoad] = useState([])
  const [result, setResult] = useState([])
  const [notes, setNotes] = useState([])
  const [loading, setLoading] = useState(false)
  const [pageLoaded, setPageLoaded] = useState(false)
  const [pageError, setPageError] = useState(false)
  useEffect(()=>{
    if(!isLoggedIn()){
      navigate(`/login`)
    }else{
      loadNotes()
    }
  },[])


  const loadNotes = (evt) =>{
    setLoading(true)
    setPageError(false)
    setPageLoaded(false)
    Axios.post(`${APIHOST}apian/wphook/myNote`, {
      mct: merchantId,
    }).then(function(res){
        let {data} = res;
        setLoading(false)
        setNotes(data.notes)
        setPageLoaded(true);
        setPageError(false)
    }).catch(function(er){
      setLoading(false)
      setPageLoaded(true);
      setPageError(true)
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          loadError(er.response.data.error)
        }else{
          loadError("A network error occured, please try again later.")
        }
      }else{
        loadError(er.message)
      }
      
    })
  }

    return (
      <>
      <div className="container bg-gray no-pad">
        <ToolBar link="notification" past="/" hasMenu={false} />
        {pageLoaded ?
        <>
        {!pageError ?
        <>
        <div id="notification">
          <div className="inner">
            <h4 className='m-title'>NOTIFICATIONS</h4>
            {notes.length == 0 ? 
            <div className="empty-list">No notifications available.</div>:
            <>
            <div className='notes'>
              {notes.map((item,i)=>(
                <div key={i} className='note'>
                  <h4>{item.title}</h4>
                  <div><small>{item.created_at}</small></div>
                  <p>{item.body}</p>
                </div>
              ))}
            </div>
            </>}
          </div>
        </div>
        </>:
        <div className='error-screen'>
          <div>
            <p>An error occured, please reload page.</p>
            <a className='reload-btn' onClick={loadNotes}>RELOAD</a>
          </div>
        </div>}
        </>:
        <div className='loader'>
          <div>
            <i className='fa fa-spinner fa-spin fa-4x'></i>
            <p>Loading...</p>
          </div>
        </div>}
      </div>
    </>
    );
}

export default Notification;
