import React, { Component, useEffect, useRef, useState } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {APIHOST,updateloginStatus,setMerchant,setToken,isLoggedIn,isOutletSet, loadSuccess, loadError, merchantKey,merchantId, RewardCDN, showLoading} from '../../../utils/gaia';
import useWindowDimensions from '../../../utils/useWindowDimensions';
import ToolBar from '../../Components/toolbar'
import WheelComponent from '../../Components/SpinWheel'

import './game.css'

import { Images } from '../../../utils/Images';
import GameWinModal from '../../Components/GameWinModal';
 
const Game = (props) => {
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [load, setLoad] = useState([])
  const [game, setGame] = useState([])
  const [slots, setslots] = useState([])
  const [slot, setSlot] = useState(0)
  const [tokens, setTokens] = useState(0)
  const [loading, setLoading] = useState(false)
  const [pageLoaded, setPageLoaded] = useState(false)
  const [pageError, setPageError] = useState(false)
  const [winnerModal, SetWinnerModal] = useState(false)
  const wheelRef = useRef();
  useEffect(()=>{
    if(!isLoggedIn()){
      navigate(`/login`)
    }else{
      loadGame()
    }
  },[])

  const onFinished = (winner) => {
    showLoading("Please wait...");
    Axios.post(`${APIHOST}apian/wphook/checkWin`, {
      key : parseFloat(winner)* 27,
      mct : merchantId,
    }).then(function(res){
        let {data} = res;
        if(data.winner){
          loadSuccess()
          openWinnerModal(data.item);
        }else{
          loadSuccess("Better Luck Next Time!.");
        }
        loadGame();
    }).catch(function(er){
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          loadError(er.response.data.error)
        }else{
          loadError("A network error occured, please try again later.")
        }
      }else{
        loadError(er.message)
      }
      loadGame();
    })
  }

  const loadGame = (evt) =>{
    setLoading(true)
    setPageError(false)
    setPageLoaded(false)
    Axios.post(`${APIHOST}apian/wphook/gamification`, {
      mct: merchantId,
    }).then(function(res){
        let {data} = res;
        setLoading(false)
        setGame(data.game)
        setTokens(data.tokens.plays)
        if(data.game !== null && typeof data.game.json_body !=  "undefined"){
          let tmp = []
          for(let i = 0; i < parseFloat(data.game.json_body); i++){
            tmp.push(i);
          }
          setslots(tmp);
        }
        setSlot(slots[Math.floor(Math.random()*(slots.length - 1)) + 1])
        setPageLoaded(true);
        setPageError(false)
    }).catch(function(er){
      setLoading(false)
      setPageLoaded(true);
      setPageError(true)
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          loadError(er.response.data.error)
        }else{
          loadError("A network error occured, please try again later.")
        }
      }else{
        loadError(er.message)
      }
      
    })
  }

  const openWinnerModal = (item = null) =>{
    if(winnerModal == false){
      if(item){
        setLoad(item)
      }
    }
    SetWinnerModal(!winnerModal);
  }



    return (
      <>
      <div className="container bg-gray no-pad">
        <ToolBar link="games" past="/" hasMenu={false} />
        {pageLoaded ?
        <>
        {!pageError ?
        <>
        <div id="game">
          <div className="inner">
            <h4 className='m-title'>SPIN THE WHEEL</h4>
            {!game ? 
            <div className="empty-list">No games available.</div>:
            <>
            {tokens == 0 ?
            <div className="empty-list">No tokens available.</div>:
            <>  
              {game.caption && <p className='caption-text'>{game.caption}</p>}
              <h4 className='plays-text'>{tokens} CHANCES</h4>
              <WheelComponent
                ref={wheelRef}
                segments={slots}
                segColors={[]}
                winningSegment={slot}
                onFinished={(winner) => onFinished(winner)}
                primaryColor='black'
                contrastColor='white'
                buttonText='Spin!'
                isOnlyOnce={true}
                wheelDiameter={width}
                size={170}
                upDuration={100}
                downDuration={200}
                fontFamily='gothic'
              />
              <button className='spin-button' onClick={()=>wheelRef.current.spin()}>SPIN!</button>
            </>
            }
            </>}
          </div>
        </div>
        </>:
        <div className='error-screen'>
          <div>
            <p>An error occured, please reload page.</p>
            <a className='reload-btn' onClick={loadGame}>RELOAD</a>
          </div>
        </div>}
        </>:
        <div className='loader'>
          <div>
            <i className='fa fa-spinner fa-spin fa-4x'></i>
            <p>Loading...</p>
          </div>
        </div>}
      </div>
      {winnerModal ? <GameWinModal item={load} open={winnerModal}  controller={()=>{openWinnerModal()}}  /> : ""}
    </>
    );
}

export default Game;
