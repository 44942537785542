import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Axios from 'axios';
import {getToken, APIHOST,merchantId} from './utils/gaia';
//import {Elements} from '@stripe/react-stripe-js';
//import { loadStripe } from "@stripe/stripe-js";

(async () =>{
  const token = getToken()
  var stripe = null;
if (token) {
    //const { data } = await Axios.post(`${APIHOST}customer/initStix`,{mct : merchantId});
    //stripe = loadStripe(data.obolous) <Elements stripe={stripe}> </Elements>
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);
})()