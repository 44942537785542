import React, { useEffect,useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Button,ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter,Collapse, FormGroup, Label, Input, Form } from 'reactstrap';
import { Images } from '../../utils/Images';


const GameWinModal = (props) => {


    useEffect(()=>{
      
    },[props])


    return (
      < >
        <Modal isOpen={props.open} toggle={() => props.controller()}>
          <ModalBody>
          <div>
            <button className="close-modal" onClick={() => props.controller()}><i className="fa fa-times"></i></button>
            <div className="clearfix"></div>
          </div>
          <div className="win-modal">
            <div className="win-modal-top mb-3">
              <div >
                <h5>CONGRATULATIONS!<br />YOU HAVE WON</h5>
              </div>
            </div>
            <div className='details-blk'>
              <img src={props.item.image != "" && props.item.image !== null && typeof props.item.image != "undefined" ? `http://newimage.space/ogdru/silas/${props.item.image}` : Images.noImage} />
              <h6>{props.item.title}</h6>
              <p>{props.item.expires}</p>
            </div>

          </div>  
          </ModalBody>
        </Modal>
      </>
    );
}

export default GameWinModal;