import React, { useEffect, useState } from 'react';
import {APIHOST,getToken,destroySession,getMerchantDetail, merchantId, merchantKey, TplCDN} from '../../utils/gaia';
import Axios from 'axios';
import {
  Navbar,
  NavItem,
  NavbarToggler,
  Collapse,
  NavLink,
  Nav,
  NavbarBrand
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import {Images} from '../../utils/Images'

const Toolbar = (props) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);
  const [fresh, setFresh] = useState();
  const [banner, setBanner] = useState("")

  useEffect(()=>{
    start()
  },[])

  const handleLogout = () =>{
      var _this = this
      Axios.post(`${APIHOST}logout`,{
        token: getToken(),
        
      }).then(function(res){
        destroySession();
        navigate(`/login`);
      }).catch(function(res){
        destroySession();
        navigate(`/login`);
      });
  }

  const start = () =>{
    getBanner();
    setInterval(()=>{
      newNote();
    },30000)
  }

  const newNote = () =>{
    Axios.post(`${APIHOST}apian/wphook/chkNote`,{
      token: getToken(),
      mct : merchantId
    }).then(function(res){
      let {data} = res;
      setFresh(data.c)
    }).catch(function(res){
    });
  }

  const getBanner = () =>{
    Axios.post(`${APIHOST}apian/wphook/getBanner`,{
      token: getToken(),
      mct : merchantId
    }).then(function(res){
      let {data} = res;
      setBanner(data.banner)
    }).catch(function(res){
    });
  }

    const toggle = () =>{
      setIsOpen(!isOpen)
    }

    return (
      <>
      <Navbar className={`fix-top top-nav ${isOpen ? 'active' : ''}`} >
        <NavLink><i className={`fa ${isOpen ? 'fa-times' : 'fa-bars' } fa-2x`} onClick={() => { setIsOpen(!isOpen) }}></i></NavLink>
        <NavbarBrand href="/">{/*<img src={Images.logo} className="central-logo" />*/}</NavbarBrand>
        <NavLink href='/app/notification'><i className='fa fa-bell fa-2x notify'>{fresh ? <span className=''></span> : ""}</i></NavLink>
        <Collapse  isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink className={props.link == "home" ? 'active' : ''}  href="/app">HOME</NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={props.link == "notification" ? 'active' : ''} href="/app/notification">NOTIFICATION</NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={props.link == "profile" ? 'active' : ''} href="/app/profile">PROFILE</NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={props.link == "transactions" ? 'active' : ''} href="/app/transactions">TRANSACTION</NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={props.link == "games" ? 'active' : ''} href="/app/games">GAMES</NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={props.link == "survey" ? 'active' : ''} href="/app/survey">SURVEY</NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={props.link == "contact" ? 'active' : ''}  href="/app/contact">CONTACT US</NavLink>
            </NavItem>
            <NavItem>
              <NavLink  onClick={handleLogout}>SIGN OUT</NavLink>
            </NavItem>
          </Nav>
                </Collapse>
            </Navbar>
        <div className=" tool-bar">
          <img src={TplCDN+banner} className="nav-banner" />
          <div className='nav-links'>
          <a href="/app" className={`navs tmpl-main-btn ${props.link == "home" ? 'inverse' : 'filled'}`}><i className='fa fa-home'></i></a>
          <a href="/app/transactions" className={`navs tmpl-main-btn ${props.link == "transactions" ? 'inverse' : 'filled'}`}>Transactions</a>
          <a href="/app/menu" className={`navs tmpl-main-btn ${props.link == "menu" ? 'inverse' : 'filled'}`}>Menu</a>
          <a href="/app/profile" className={`navs tmpl-main-btn ${props.link == "profile" ? 'inverse' : 'filled'}`}>Profile</a>
        </div>
        </div>
        
      </>
    );
      
}

export default Toolbar;