import React, { useEffect,useState } from 'react';
import { Modal, ModalBody, FormGroup, Label, Input, Form } from 'reactstrap';

const GiftModal = (props) => {
    const [password, setPassword] = useState(props.password)
    const [loading ,setLoading] = useState(false);
    const [loaded ,setLoaded] = useState(false);
    const [error ,setError] = useState(false);

    useEffect(()=>{
      setPassword(props.password)
    },[props])


    return (
      < >
        <Modal isOpen={props.open} toggle={() => props.controller()} centered>
          <ModalBody>
          <div>
            <button className="close-modal" onClick={() => props.controller()}><i className="fa fa-times"></i></button>
            <div className="clearfix"></div>
          </div>
          <div className="rp-feed">
            <div className="points-pill mb-3">
              <div >
                <h5>Send gift to user</h5>
              </div>
            </div>
            <div className='qr-blk'>
              <Form autoComplete='off'>
                <FormGroup className='manual-input'>
                  <Label for="username">RECIPIENTS PHONE NUMBER</Label>
                  <Input className="text-field" type="number" id="recipient" name="recipient"  placeholder='Enter Phone Number' value={props.recipient} onChange={event => props.handleChange(event.target.value)} />
                </FormGroup>
              </Form>
              <small style={{fontSize : "10px"}}>*All rewards that are unclaimed after 30 days<br />will be returned to your account.</small>
            </div>
            <button onClick={props.redeem} className="tmpl-main-btn filled">Done</button>
          </div>  
          </ModalBody>
        </Modal>
      </>
    );
}

export default GiftModal;