import React, { Component, useEffect, useState } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {APIHOST, TplCDN,isLoggedIn,isOutletSet, loadSuccess, loadError, merchantKey,merchantId, showLoading, setBanner} from '../../../utils/gaia';
import ToolBar from '../../Components/toolbar'
import RedeemPointsModal from '../../Components/RedeemPointsModal'
import BundleModal from '../../Components/BundleModal'
import GiftModal from '../../Components/GiftModal'
import classnames from 'classnames';


import { TabPane, TabContent,Nav, NavItem,NavLink, Row, Col, Collapse } from 'reactstrap';
import { Images } from '../../../utils/Images';
import ClaimModal from '../../Components/ClaimModal';
import QrModal from '../../Components/QrModal';
import PopupModal from '../../Components/PopupModal';

const loadInit = {
  wlt : '',
  points : [],
  pkgs : [],
  usable_pkgs : [],
  rwds : [],
  usable_rwds : []
}
 
const Dashboard = (props) => {
  const navigate = useNavigate();
  const [mct, setMct] = useState("")
  const [toCash, setToCash] = useState("")
  const [myWlt, setMyWlt] = useState("")
  const [isBday, setIsBday] = useState("")
  const [pointSys, setPointSys] = useState([])
  const [tmpPnts, setTmpPnts] = useState([])
  const [tmpBdls, setTmpBdls] = useState([])
  const [isGuest, setIsGuest] = useState("")
  const [cmpType, setCmpType] = useState("")
  const [group, setGroup] = useState("")
  const [packages, setPackages] = useState([])
  const [bundles, setBundles] = useState([])
  const [bdls, setBdls] = useState([])
  const [collapsibles, setCollapsibles] = useState([])
  const [points, setPoints] = useState("")
  const [stampRwd, setStampRwd] = useState("")
  const [stamps, setStamps] = useState("")
  const [password, setPassword] = useState("")
  const [recipient, setRecipient] = useState("")
  const [nextRwd, setNextRwd] = useState("")
  const [cRwds, setCRwds] = useState("")
  const [cardLength, setCardLength] = useState("")
  const [qr, setQr] = useState("")
  const [activeBundle, setActiveBundle] = useState([])
  const [cardSlots, setCardSlots] = useState([])
  const [usableR, setUsableR] = useState([])
  const [wallet, setWallet] = useState("")
  const [rewards, setRewards] = useState([])
  const [loading, setLoading] = useState(false)
  const [pageLoaded, setPageLoaded] = useState(false)
  const [pageError, setPageError] = useState(false)
  //modals
  const [pointsModal, SetPointsModal] = useState(false)
  const [bundleModal, SetBundleModal] = useState(false)
  const [claimModal, SetClaimModal] = useState(false)
  const [giftModal, SetGiftModal] = useState(false)
  const [qrModal, SetQrModal] = useState(false)
  const [popModal, SetPopModal] = useState(false)

  const [popupImage, setPopupImage] = useState("")
  const [popupLink, setPopupLink] = useState("")

  const [bannerImage, setBannerImage] = useState("")

  const [activeTab, setActiveTab] = useState('rewards')
  const [load, setLoad] = useState(loadInit)

  useEffect(()=>{
    if(!isLoggedIn()){
      navigate(`/login`)
    }else{
      loadLoyalty()
    }
  },[])

  const toggle = (tab) =>{
    setActiveTab(tab);
  }

  const loadLoyalty = (evt) =>{
    

    setLoading(true)
    setPageError(false)
    setPageLoaded(false)
    setLoad(loadInit)
  
    Axios.post(`${APIHOST}apian/wphook/overview`, {
      mct: merchantId,
    }).then(function(res){
        let {data} = res;
        setLoading(false)
        setMct(data.mct)
        setMyWlt(data.mywlt)
        setIsBday(data.isBday)
        setCmpType(data.cmp_type)
        setIsGuest(data.isGuest)
        setGroup(data.group)
        setPackages(data.packages)
        setBundles(data.bundles)
        setStamps(data.stamps)
        setPoints(data.points)
        setStampRwd(data.stamp_rwd)
        setNextRwd(data.next_rwd)
        setRewards(data.rewards)
        setWallet(data.wallet)
        setCardSlots(data.stamp_card_slots)
        setCardLength(data.stamp_card_length)
        setPointSys(data.point_sys)
        setCRwds(data.cRwds)
        setBannerImage(TplCDN+data.banner)
        setBanner(TplCDN+data.banner)

        if(data.popup_image != ""){
          setPopupImage(TplCDN+data.popup_image);
          openPopModal();
        }
        
        setPageLoaded(true);
        setPageError(false)
    }).catch(function(er){
      setLoading(false)
      setPageLoaded(true);
      setPageError(true)
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          loadError(er.response.data.error)
        }else{
          loadError("A network error occured, please try again later.")
        }
      }else{
        loadError(er.message)
      }
      
    })
  }

  const selectReward = (evt) =>{
    
    let el = evt.target.id;
    let title = evt.target.title;
    let id = el.replace('rwd_',"");
    let tmp_ur = load.usable_rwds;
    let tmp_r = load.rwds;
    
    let index = tmp_r.indexOf(id)
    
    if(index > -1){
      setLoad({...load, usable_rwds : tmp_ur.splice(index,1)})
      setLoad({...load, rwds : load.rwds.splice(index,1)})
    }else{
      setLoad({...load, usable_rwds : tmp_ur.push(title)})
      setLoad({...load, rwds : load.rwds.push(id)})
    }
    setLoad(load)
  }

  const selectPoints = (evt,itm) =>{

    let el = evt.target.id;
    let title = evt.target.title;
    let id = el.replace('pnt_',"");
    let tmp = tmpPnts
    let index = tmp.indexOf(id)
    let pt = points;
    
    if(index > -1){
      tmp = tmp.splice(index,1)
      pt = parseFloat(pt) + parseFloat(itm.points)
    }else{
      if(parseInt(pt) < parseInt(itm.points)){
        loadError("You do not have enough points left to redeem this reward")
        return null;
      }
      setTmpPnts(old => [...old,id])
      pt = parseFloat(pt) - parseFloat(itm.points)
    }
    
    setPoints(pt)
  }

  const selectBundleItem = (evt,itm) =>{

    let el = evt.target.id;
    let title = evt.target.title;
    let id = el.replace('bdl_',"");
    let tmp = tmpBdls
    let index = tmp.indexOf(id);
    
    if(index > -1){
      tmp = tmp.splice(index,1)
      setTmpBdls(tmp)
    }else{
      setTmpBdls(old => [...old,id])
    }
    
  }

  const claimPoints = () =>{
    if(tmpPnts.length == 0){
      loadError("Please select an item to redeem.");
      return
    }
    showLoading("Redeeming Points...")
    Axios.post(`${APIHOST}apian/wphook/claimPoints`, {
      mct: merchantId,
      points : tmpPnts
    }).then(function(res){
        let {data} = res;
        loadSuccess();
        loadLoyalty()
        setTmpPnts([])
        SetPointsModal(false)
    }).catch(function(er){
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          loadError(er.response.data.error)
        }else{
          loadError("A network error occured, please try again later.")
        }
      }else{
        loadError(er.message)
      }
      
    })

  }

  const giftBundle = () =>{
    if(tmpBdls.length == 0){
      loadError("Please select an item to redeem.");
      return
    }
    if(recipient == "" || recipient.length != 8){
      loadError("Invalid Phone number provided. Phone number should be 8 digits long.");
      return
    }
    showLoading("Processing request...")
    Axios.post(`${APIHOST}apian/wphook/giftBundleItem`, {
      mct: merchantId,
      recipient : recipient,
      items : tmpBdls
    }).then(function(res){
        let {data} = res;
        loadSuccess();
        loadLoyalty()
        setTmpBdls([])
        toggleBundleModal()
        closeGiftModal()
    }).catch(function(er){
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          loadError(er.response.data.error)
        }else{
          loadError("A network error occured, please try again later.")
        }
      }else{
        loadError(er.message)
      }
      
    })

  }

  const redeemBundle = () =>{
    if(tmpBdls.length == 0){
      loadError("Please select an item to redeem.");
      return
    }
    showLoading("Redeeming Package Items...")
    Axios.post(`${APIHOST}apian/wphook/redeemBundleItem`, {
      mct: merchantId,
      items : tmpBdls
    }).then(function(res){
        let {data} = res;
        loadSuccess();
        loadLoyalty()
        setTmpBdls([])
        toggleBundleModal()
    }).catch(function(er){
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          loadError(er.response.data.error)
        }else{
          loadError("A network error occured, please try again later.")
        }
      }else{
        loadError(er.message)
      }
      
    })

  }

  const redeemItems = () =>{

    showLoading("Generating redeemable QR code...")
    Axios.post(`${APIHOST}apian/wphook/genQr`, {
      mct: merchantId,
      //points : tmpPnts,
      wlt :  toCash,
      rwds :  load.rwds,
      pkgs : load.pkgs,
    }).then(function(res){
        let {data} = res;
        loadSuccess();
        SetClaimModal(false)
        setQr(data.msg)
        openQrModal();
    }).catch(function(er){
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          loadError(er.response.data.error)
        }else{
          loadError("A network error occured, please try again later.")
        }
      }else{
        loadError(er.message)
      }
      
    })

  }

  const manualClaim = () =>{
    if(password == ""){
      setTmpPnts([]);
      setToCash("");
      setLoad(loadInit);
      openQrModal()
    }else{
      showLoading("Processing QR code...")
      Axios.post(`${APIHOST}apian/wphook/processQr`, {
        merchant: merchantId,
        code: qr,
        type: 'slug',
        password: password
      }).then(function(res){
        let {data} = res;
        loadSuccess();
        openQrModal();
        setTmpPnts([]);
        setToCash("");
        setLoad(loadInit);
        loadLoyalty()
      }).catch(function(er){
        if(typeof(er.response) !== "undefined"){
          if(er.response.status == 404 || er.response.status == 500){
            loadError(er.response.data.error)
          }else{
            loadError("A network error occured, please try again later.")
          }
        }else{
          loadError(er.message)
        }
      })
    }
  }

  const genItmDesc = (deductible,action_clause,value) =>{
    let act = action_clause == "cash" ? `$${value}` : `${deductible}%`;
    return deductible == 1 ? `${act} discount off selected item` : "";
  }

  const updWalletAmt = (evt) =>{
    var wlt;
    if(parseFloat(evt.target.value) > myWlt){
      loadError(`You can only use a maximum of $${myWlt}.`)
      setToCash(myWlt);
    }else{
      setToCash(evt.target.value == "" ? "" : parseFloat(evt.target.value));
    }
  }

  const useAll = () =>{
    let tmp = load;
    tmp.wlt = myWlt;
    setLoad(tmp);
    setToCash(myWlt)
  }

  const openPointsModal = () =>{
    SetPointsModal(!pointsModal);
  }

  const openGiftModal = () =>{
    if(tmpBdls.length == 0){
      loadError("Please select an item to redeem.");
      return
    }
    SetGiftModal(!giftModal);
  }

  const closeGiftModal = () =>{
    setRecipient("")
    SetGiftModal(!giftModal);
  }

  const toggleBundleModal = (bundle = []) =>{
    setActiveBundle(bundle)
    SetBundleModal(!bundleModal)
  }

  const openQrModal = () =>{
    if(qrModal == true){
      setTmpPnts([]);
      setToCash("");
      setLoad(loadInit);
    }
    SetQrModal(!qrModal);
  }

  const itemSelected = (id) =>{
    if(bdls.indexOf(id.toString()) > -1){
      return true;
    }else{
      return false;
    }
  }

  const selecItem = (e,itm) =>{
    var id = e.currentTarget.id;
    var cname = document.getElementById(id).className;
    var element = document.getElementById(id);
    var usable_id = id.replace("itm_", "");
    var usable_pkgs = this.state.pkgs;
    var pkg_name = this.state.pkg_name;
    var index = usable_pkgs.indexOf(usable_id);
    if(cname == "pkg-itm itm mt-1 itm-active"){
      element.classList.remove("itm-active");
      //remove item from rwds array
      if (index > -1) {
        usable_pkgs.splice(index, 1);
        pkg_name.splice(index, 1);
        this.setState({pkgs : usable_pkgs,pkg_name : pkg_name, usable_load : this.state.usable_load.filter((itm)=>{ return itm.id !== usable_id})})
      }
    }else{
      element.classList.add("itm-active");
      //add item to rwds array
      if (index == -1) {
        usable_pkgs.push(usable_id);
        pkg_name.push(itm);
        this.setState({pkgs : usable_pkgs,pkg_name : pkg_name, usable_load : [...this.state.usable_load,{id:usable_id, name : itm}] })
      }
    }
  }

  

  const toggleCollapse = (el) =>{
    setCollapsibles(Object.assign({}, collapsibles, {[el] : !collapsibles[el]}))
  }

  const openPopModal = () =>{
    SetPopModal(!popModal);
  }

  const openClaimModal = () =>{
    if(claimModal == false){
      
      if(load.usable_pkgs.length == 0 && load.usable_rwds.length == 0 && (toCash == "" || toCash == 0 || isNaN(toCash))){
        loadError("Please enter a wallet amount, select a reward or select a package to redeem.")
        return;
      }
    }
    
    SetClaimModal(!claimModal);
  }

    return (
      <>
      <div className="container bg-gray no-pad">
        <ToolBar banner={bannerImage} link="home" past="/" hasMenu={false} />
        {pageLoaded ?
        <>
        {!pageError ?
        <>
        <div id="dashboard">
          <div className="inner">
            <div className='wlt-blk mb-4'>
              <div className="points-bar row ">
                {cmpType == "points" ?
                <div className="col-6">
                  <div className="point-item" onClick={openPointsModal}>
                    <h3>{points} Points</h3>
                    <a className="show-points">Click here to redeem</a>
                  </div>
                </div>:""}
                {cmpType == "stamps" ?
                <div className="col-6">
                  <div className="point-item">
                      <h3>{`${stamps}/${cardLength}`}</h3>
                      <a href="/stamp">Stamps / Total Stamps</a>
                  </div>
                </div>: ""}
              </div>
              <div className="points-bar row ">
                <div className="col-6">
                  <div className="point-item">
                    <h3>${parseFloat(myWlt).toFixed(2)}</h3>
                    <a href="#">Wallet Balance</a>
                </div>
              </div>

              <div className="col-6">
                <div className="point-item key-point">
                    <input id="wlt-amt" type="number" value={toCash} onChange={(evt) => updWalletAmt(evt)} max={parseFloat(myWlt).toFixed(2)} placeholder="key in amount" />
                    <button onClick={useAll} className="circle-bg">ALL</button>
                </div>
              </div>
              </div>
            </div>
            <div> 
                <Nav tabs className='nav-fill'>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === 'rewards' })}  onClick={() => { toggle('rewards'); }}>Rewards</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === 'coupons' })}  onClick={() => { toggle('coupons'); }}>Packages</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === 'packages' })}  onClick={() => { toggle('packages'); }}>Bundles</NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="rewards">
                    <div className="reward-list pl-2 pr-2">
                      <div className="row m-0">
                        {rewards.length == 0 ?
                        <div className="empty-list">No rewards available.</div>:
                        <>
                        {rewards.map(rwd=>(
                        <div key={rwd.id} className="col-6">
                          <div className="reward-item" for={`rwd_${rwd.id}`}>
                          <div className='item-inner'>
                          <a href="#">
                            <img src={rwd.image != "" && rwd.image !== null ? `http://newimage.space/ogdru/silas/${rwd.image}` : Images.noImage} />
                          </a>
                          <div className="reward-content">
                            <div className="reward-content-inner">
                              <h3 className="sky-text">{rwd.title}</h3>
                              <h4 className="sky-text">{()=>genItmDesc(rwd.deductible,rwd.action_clause,rwd.value)}</h4>
                              <p>Expires : {rwd.expires}</p>
                            </div>
                            <div className="custom-radio">
                              <input type="checkbox" name="reward" title={rwd.title} id={`rwd_${rwd.id}`} onChange={(evt)=>selectReward(evt)} />
                              <label for={`rwd_${rwd.id}`}></label>
                            </div>
                          </div>
                          </div>
                        </div>
                    </div>
                    ))}
                    </>}
                  </div>
                </div>
                  </TabPane>
                  <TabPane tabId="packages">
                  <Row>
                    <Col sm="6"></Col>
                    <Col sm="6"></Col>
                  </Row>
                  </TabPane>
                  <TabPane tabId="coupons">
                  <Row>
                    <Col sm="12">
                    {bundles.length > 0 ? 
                      <div className="rp-itm-blk">
                      {bundles.map(bdl =>(
                        <div key={bdl.id} className="bundle-list mt-2">
                          <div className="bundle"  onClick={()=>toggleBundleModal(bdl)}>
                            <h4>{bdl.package_title} <small>({bdl.items.length} left)</small></h4>
                          </div>
                        </div>
                      ))}
                      </div>: <div className="empty-list">No Packages Available</div>}
                    </Col>
                  </Row>
                </TabPane>
                </TabContent>
            </div>
          </div>
        </div>
        <div className='footer'>
          <a className=''><i className='fa fa-question-circle'></i> FAQ</a>
          <a className='' onClick={openClaimModal}><i className='fa fa-qrcode'></i> REDEEM</a>
          <img src={Images.powered} className="powered" />
        </div>
        </>:
        <div className='error-screen'>
          <div>
            <p>An error occured, please reload page.</p>
            <a className='reload-btn' onClick={loadLoyalty}>RELOAD</a>
          </div>
        </div>}
        </>:
        <div className='loader'>
          <div>
            <i className='fa fa-spinner fa-spin fa-4x'></i>
            <p>Loading...</p>
          </div>
        </div>}
      </div>
      {pointsModal && <RedeemPointsModal pnts={tmpPnts} selectPoints={(e,itm)=>selectPoints(e,itm)} open={pointsModal} points={points} point_sys={pointSys} controller={()=>{openPointsModal()}} claim={claimPoints} />}
      {bundleModal && <BundleModal bdls={tmpBdls} selectBundleItem={(e,itm)=>selectBundleItem(e,itm)} open={bundleModal}  bundle={activeBundle} controller={()=>{toggleBundleModal()}} redeem={redeemBundle} gift={openGiftModal} /> }
      {giftModal && <GiftModal bdls={tmpBdls} recipient={recipient} handleChange={(e)=>{setRecipient(e)}} selectBundleItem={(e,itm)=>selectBundleItem(e,itm)} open={giftModal}  bundle={activeBundle} controller={()=>{closeGiftModal()}} redeem={giftBundle} /> }
      {claimModal ? <ClaimModal   open={claimModal} items={load} cash={toCash} controller={()=>{openClaimModal()}} claim={redeemItems} /> : ""}
      {qrModal ? <QrModal items={load} password={password} handleChange={(e)=>{setPassword(e)}} cash={toCash} open={qrModal} qr={qr} controller={()=>{openQrModal()}} claim={manualClaim} /> : ""}
      {popModal ? <PopupModal  open={popModal} popup={popupImage} controller={()=>{openPopModal()}}  /> : ""}
    </>
    );
}

export default Dashboard;
